import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';

import { Box } from './abstract';
import { Carousel } from './';

const CarouselWrapper = styled(Box)`
  position: relative;

  width: ${props => (props.participate ? '20%' : 'auto')};
  height: ${props => (props.participate ? '100%' : 'auto')};

  margin-right: ${props => (props.participate ? '1.4rem' : '0')};

  border-radius: ${props => (props.detailVehicle ? '0' : '0.8rem')};
  overflow: hidden;

  @media screen and (max-width: 1025px) {
    ${({ participate }) =>
      participate &&
      css`
        width: 100%;
        height: auto;

        margin-right: 0;
        margin-bottom: 1.25rem;
      `}
  }

  ${({ notifications }) =>
    notifications &&
    css`
      position: relative;
      overflow: hidden;
      border-radius: 0;
    `}
`;

export const CarouselPhoto = trackWindowScroll(
  ({
    photos = [],
    height = 200,
    highlighted,
    scrollPosition,
    children,
    detailVehicle,
    participate,
    notifications,
    showMaximumQualitySeal = false,
    ...etc
  }) =>
    photos && (
      <CarouselWrapper
        notifications={notifications}
        detailVehicle={detailVehicle}
        participate={participate}
      >
        <Carousel
          height={height}
          participate={participate}
          showMaximumQualitySeal={showMaximumQualitySeal}
          {...etc}
        >
          {photos.map((photo, i) => (
            <LazyLoadImage
              key={photo.img ? photo.img.src : photo.src || photo}
              src={photo.img ? photo.img.src : photo.src || photo}
              alt={photo.img ? photo.img.alt : ''}
              effect="opacity"
              scrollPosition={scrollPosition}
              wrapperClassName="slide lazy"
              className="lazy"
            />
          ))}
        </Carousel>
        {children}
      </CarouselWrapper>
    )
);
