import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  display: flex;
`;

export const ImgWrapper = styled.div`
  padding-left: 5px;
`;
