import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(24, 94, 136, 0.15);
  padding: 25px;
  width: 92%;
  max-width: 92%;
  border-radius: 8px;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 83px;
  @media screen and (max-width: 1025px) {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    height: 138px;
    padding: 15px;
  }
`;

export const GenerateContainer = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(24, 94, 136, 0.15);
  padding: 25px;
  width: 92%;
  max-width: 92%;
  border-radius: 8px;
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    width: 100%;
    max-width: 100%;
    height: 70px;
    padding: 15px;
  }
`;

export const DownloadButton = styled.a`
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.24);
  width: 196px;
  height: 43px;
  border-radius: 5px;
  opacity: 0px;
  background-color: rgba(255, 108, 28, 1);
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 90px;
  @media screen and (max-width: 1025px) {
    margin-right: 0px;
  }
`;

export const Text = styled.div`
  padding-left: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const TextInfo = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  @media screen and (max-width: 1025px) {
    text-align: center;
  }
`;

export const NormalText = styled.span`
  font-weight: normal;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const ValueContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: end;
`;

export const ModalTitle = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  width: 60%;

  @media screen and (max-width: 1025px) {
    width: 80%;
  }
`;

export const ModalTitleContainer = styled.div`
  justify-content: center;
  display: flex;
`;

export const SubTitleModal = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  width: 80%;

  @media screen and (max-width: 1025px) {
    width: 95%;
  }
`;

export const SubTitleModalContainer = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 20px;
`;

export const SubTitleModalBold = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-align: center;
`;

export const ConfirmButton = styled.a`
  width: 360px;
  height: 48px;
  border-radius: 6px;
  background-color: rgba(34, 170, 82, 1);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const ConfirmButtonText = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
`;

export const ConfirmButtonContainer = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 40px;
`;

export const CancelText = styled.a`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: rgba(38, 50, 56, 1);
  text-decoration: underline;
`;

export const CancelTextContainer = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 10px;
`;

export const CloseIconContainer = styled.div`
  justify-content: end;
  display: flex;
`;

export const ModalContent = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const CloseIconDiv = styled.a``;

export const WaitContainer = styled.div`
  width: 121px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(159, 160, 161, 1);
  border-radius: 4px;
  margin-right: 90px;

  @media screen and (max-width: 1025px) {
    margin-right: 0px;
  }
`;

export const WaitText = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: rgba(159, 160, 161, 1);
`;

export const DownloadContainer = styled.a`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-right: 90px;

  @media screen and (max-width: 1025px) {
    margin-right: 0px;
  }
`;

export const DownloadText = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  padding-left: 10px;
  padding-top: 4px;
  text-decoration: underline;
  color: rgba(0, 168, 31, 1);
`;
