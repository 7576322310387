import React, { useCallback, useMemo, useState } from 'react';
import { MdVerifiedUser } from 'react-icons/md';
import { TiLocation } from 'react-icons/ti';

import {
  Container,
  SpanRow,
  Content,
  List,
  ListItem,
  Badge,
  BadgeIcon,
  BadgeContent,
  BadgeTitle,
  BadgeDescription,
  Divider,
  BadgeLink,
  Row,
  Column,
  VehicleArea,
  BadgeArea,
  BadgeTile,
  ScoreLocation,
} from './styles';

import Precautionary from './images/cautelar.svg';
import Km from './images/km.svg';
import Mecanica from './images/mecanica.svg';
import Margem from './images/margem.svg';
import Giro from './images/giro.svg';

import Competitive from './images/concorrido.svg';
import Favorite from './images/favorito.svg';
import ProductNF from './images/nf.svg';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { toggleModal } from '../../../../store/actions/ui.actions';
import { PRODUCT_NF } from '../../../../constants/modalConstants';
import { useDispatch } from 'react-redux';
import { ReferencePrices } from '../ReferencePrices';
import { useFlag } from '@unleash/proxy-client-react';
import MaximumQuality from '../MaximumQuality';
import { DocumentResearch } from '../DocumentResearch';

const fuel = [
  'GASOLINA',
  'FLEX',
  'ETANOL',
  'DIESEL',
  'HIBRIDO',
  'HYBRID',
  'ELETRICO',
  'GASOLINA/GNV',
  'GASOLINA/ETANOL',
  'GASOLINA/ETANOL/GNV',
  'GASOLINA/ELETRICO',
  'GASOLINA/ETANOL/ELETRICO',
  'HYBRID FLEX',
  'HIBRIDO FLEX',
  'JETFLEX GASOLINA',
];

export const Summary = props => {
  const { auction, summary, marketPrice } = props;
  const dispatch = useDispatch();
  const enabled = useFlag('fipe-web-prices');
  const enabledScore = useFlag('score');

  const [size, setSize] = useState(window.screen.width);

  const injuctionNote = summary?.evaluation?.highlights?.map(({ icon, description }) =>
    icon === 11 ? description : null
  );

  const showBadges =
    auction?.vehicle?.precautionaryReport ||
    auction?.vehicle?.kilometrageAcceptable ||
    auction?.vehicle?.allMechanicsCorrect ||
    auction?.vehicle?.isBelowThreshold ||
    auction?.vehicle?.fastSpin;

  const highlights = summary?.evaluation?.highlights?.map(({ description }) =>
    description
      .normalize('NFD')
      .replace(/\p{Mn}/gu, '')
      .replace(/[-]/g, ' ')
      .toUpperCase()
  );

  const injuctionWithoutNote = highlights?.includes('PERICIA CAUTELAR APROVADA');

  const getVersionByFuel = useCallback(() => {
    let matched;

    fuel.forEach(f => {
      if (
        summary?.version
          .normalize('NFD')
          .replace(/\p{Mn}/gu, '')
          .toUpperCase()
          .includes(f)
      ) {
        matched = f.charAt(0).toUpperCase() + f.slice(1).toLowerCase();

        if (matched === 'Eletrico') {
          matched = 'Elétrico';
        }

        if (matched === 'Hibrido' || matched === 'Hybrid') {
          matched = 'Híbrido';
        }

        if (matched === 'Hibrido flex' || matched === 'Hybrid flex') {
          matched = 'Híbrido/Flex';
        }

        if (matched === 'Jetflex gasolina') {
          matched = 'Gasolina';
        }
      }
    });

    if (summary?.summary.toUpperCase().includes('GNV')) {
      return `${matched}/GNV`;
    }

    return matched;
  }, [summary]);

  const getHighlightsByManualAndSpareKey = useCallback(() => {
    const missingAll = highlights?.find(h => h.includes('MANUAL E CONJUNTO DE CHAVES AUSENTE'));
    const missingSpareKey = highlights?.find(h => h.includes('CONJUNTO DE CHAVES AUSENTE'));

    const missingManual = highlights?.find(
      h => h.includes('MANUAL AUSENTE') || h.includes('MANUAL ORIGINAL AUSENTE')
    );

    if (missingAll) {
      return 'Não';
    } else if (missingSpareKey && !missingManual) {
      return 'Não/Sim';
    } else if (!missingSpareKey && missingManual) {
      return 'Sim/Não';
    } else {
      return 'Sim';
    }
  }, [highlights]);

  const getGear = useMemo(() => {
    if (summary?.version?.includes('MANUAL')) {
      return 'Manual';
    }

    return summary?.version?.includes('AUTOMATIZADO') ? 'Automatizado' : 'Automático';
  }, [summary]);

  const toggleProductNFModal = () => dispatch(toggleModal({ key: PRODUCT_NF, show: true }));

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Container enabled={enabled}>
      <VehicleArea>
        {enabled && <ReferencePrices marketPrice={marketPrice} webPrice={summary?.webPrice} />}

        {size <= 1024 && showBadges ? (
          <BadgeArea enabled={enabled}>
            {/* {size <= 1024 ? <Divider /> : null} */}
            <BadgeTile>Destaques da oferta</BadgeTile>
            {auction?.vehicle?.precautionaryReport ? (
              <>
                <Badge>
                  <BadgeIcon src={Precautionary} />
                  <BadgeContent>
                    <BadgeTitle>Cautelar aprovada</BadgeTitle>
                    <BadgeDescription>Cautelar verificada e aprovada</BadgeDescription>
                  </BadgeContent>
                </Badge>
                <Divider />
              </>
            ) : null}

            {auction?.vehicle?.kilometrageAcceptable ? (
              <>
                <Badge>
                  <BadgeIcon src={Km} />
                  <BadgeContent>
                    <BadgeTitle>Boa km por ano</BadgeTitle>
                    <BadgeDescription>Na média de km para carros do mesmo ano</BadgeDescription>
                  </BadgeContent>
                </Badge>
                <Divider />
              </>
            ) : null}

            {auction?.vehicle?.allMechanicsCorrect ? (
              <>
                <Badge>
                  <BadgeIcon src={Mecanica} />
                  <BadgeContent>
                    <BadgeTitle>Mecânica sem apontamentos</BadgeTitle>
                    <BadgeDescription>Conjunto mecânico em ótimas condições</BadgeDescription>
                  </BadgeContent>
                </Badge>
                <Divider />
              </>
            ) : null}

            {auction?.vehicle?.isBelowThreshold ? (
              <>
                <Badge>
                  <BadgeIcon src={Margem} />
                  <BadgeContent>
                    <BadgeTitle>Margem alta na revenda</BadgeTitle>
                    <BadgeDescription>70% abaixo do preço da web</BadgeDescription>
                  </BadgeContent>
                </Badge>
                <Divider />
              </>
            ) : null}

            {auction?.vehicle?.fastSpin ? (
              <>
                <Badge>
                  <BadgeIcon src={Giro} />
                  <BadgeContent>
                    <BadgeTitle>Giro rápido</BadgeTitle>
                    <BadgeDescription>Mais vendidos segundo a Fenabrave</BadgeDescription>
                  </BadgeContent>
                </Badge>
                <Divider />
              </>
            ) : null}
          </BadgeArea>
        ) : null}

        <ScoreLocation>
          {/*           {enabledScore ? (
            <SpanRow>
              <MdVerifiedUser
                size={27}
                color="#22AA52"
                style={{ verticalAlign: 'text-bottom', marginRight: '0.3em' }}
              />
              Aprovado em {Math.round(summary?.evaluation?.goodPointsCount || 0)}% dos pontos
            </SpanRow>
          ) : null} */}

          <SpanRow>
            <TiLocation
              size={27}
              color="#80BEB0"
              style={{ verticalAlign: 'text-top', marginRight: '0.3em' }}
            />
            Retirada em {summary?.vehicleYardRegion ?? summary?.state}*
          </SpanRow>
        </ScoreLocation>

        <Row>
          <Column>
            <List>
              <ListItem>
                <span>Ano</span>
                <strong>{summary?.year}</strong>
              </ListItem>
              <ListItem>
                <span>Km</span>
                <strong>
                  {summary?.km?.toLocaleString('pt-BR', { minimumFractionDigits: 0 })}
                </strong>
              </ListItem>
              <ListItem>
                <span>Câmbio</span>
                <strong>{getGear}</strong>
              </ListItem>
              <ListItem>
                <span>Cautelar</span>
                <strong>{injuctionNote}</strong>
              </ListItem>
            </List>
          </Column>

          <Column>
            <List>
              <ListItem>
                <span>Combustível</span>
                <strong>{getVersionByFuel()}</strong>
              </ListItem>
              <ListItem>
                <span>Final da Placa</span>
                <strong>{String(summary?.plateNumber).substring(7)}</strong>
              </ListItem>
              <ListItem>
                <span>Emplacado em</span>
                <strong>{summary?.plateLocation}</strong>
              </ListItem>
              <ListItem>
                <span>Conjunto de chaves/Manual</span>
                <strong>{getHighlightsByManualAndSpareKey()}</strong>
              </ListItem>
            </List>
          </Column>
        </Row>
      </VehicleArea>

      {size <= 1024 ? (
        <>
          <DocumentResearch showObservations research={summary} />
          <MaximumQuality />
        </>
      ) : null}

      {size >= 1024 && showBadges ? (
        <BadgeArea enabled={enabled}>
          {/* {size <= 1024 ? <Divider /> : null} */}
          <BadgeTile>Destaques da oferta</BadgeTile>
          {auction?.vehicle?.precautionaryReport ? (
            <>
              <Badge>
                <BadgeIcon src={Precautionary} />
                <BadgeContent>
                  <BadgeTitle>Cautelar aprovada</BadgeTitle>
                  <BadgeDescription>Cautelar verificada e aprovada</BadgeDescription>
                </BadgeContent>
              </Badge>
              <Divider />
            </>
          ) : null}

          {auction?.vehicle?.kilometrageAcceptable ? (
            <>
              <Badge>
                <BadgeIcon src={Km} />
                <BadgeContent>
                  <BadgeTitle>Boa km por ano</BadgeTitle>
                  <BadgeDescription>Na média de km para carros do mesmo ano</BadgeDescription>
                </BadgeContent>
              </Badge>
              <Divider />
            </>
          ) : null}

          {auction?.vehicle?.allMechanicsCorrect ? (
            <>
              <Badge>
                <BadgeIcon src={Mecanica} />
                <BadgeContent>
                  <BadgeTitle>Mecânica sem apontamentos</BadgeTitle>
                  <BadgeDescription>Conjunto mecânico em ótimas condições</BadgeDescription>
                </BadgeContent>
              </Badge>
              <Divider />
            </>
          ) : null}

          {auction?.vehicle?.isBelowThreshold ? (
            <>
              <Badge>
                <BadgeIcon src={Margem} />
                <BadgeContent>
                  <BadgeTitle>Margem alta na revenda</BadgeTitle>
                  <BadgeDescription>70% abaixo do preço da web</BadgeDescription>
                </BadgeContent>
              </Badge>
              <Divider />
            </>
          ) : null}

          {auction?.vehicle?.fastSpin ? (
            <>
              <Badge>
                <BadgeIcon src={Giro} />
                <BadgeContent>
                  <BadgeTitle>Giro rápido</BadgeTitle>
                  <BadgeDescription>Mais vendidos segundo a Fenabrave</BadgeDescription>
                </BadgeContent>
              </Badge>
              <Divider />
            </>
          ) : null}

          {/*         {summary?.nf ? (
          <>
            <Badge>
              <BadgeIcon src={ProductNF} />
              <BadgeContent>
                <BadgeTitle color="#6D1974">
                  NF de produto
                  <AiOutlineQuestionCircle
                    fill="#2f3741"
                    size={20}
                    onClick={toggleProductNFModal}
                    cursor="pointer"
                  />
                </BadgeTitle>
                <BadgeDescription>
                  Vendedor CNPJ emite NF.{' '}
                  <BadgeLink color="#6D1974" onClick={toggleProductNFModal}>
                    Saiba mais
                  </BadgeLink>
                </BadgeDescription>
              </BadgeContent>
            </Badge>
            <Divider />
          </>
        ) : null} */}

          {/*         {auction?.numberOfDealersParticipating >= 4 ? (
          <>
            <Badge>
              <BadgeIcon src={Competitive} />
              <BadgeContent>
                <BadgeTitle color="#22AA52">Concorrido!</BadgeTitle>
                <BadgeDescription>
                  {auction?.numberOfDealersParticipating} lojistas estão <br /> participando desse
                  leilão.
                </BadgeDescription>
              </BadgeContent>
            </Badge>
            <Divider />
          </>
        ) : null} */}

          {/*        {auction?.favoriteCount >= 2 ? (
          <Badge>
            <BadgeIcon src={Favorite} />
            <BadgeContent>
              <BadgeTitle color="#DB4647">Favorito</BadgeTitle>
              <BadgeDescription>Favorito entre os lojistas.</BadgeDescription>
            </BadgeContent>
          </Badge>
        ) : null} */}
        </BadgeArea>
      ) : null}
    </Container>
  );
};
