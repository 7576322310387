import React from 'react';

import { Container, ImgWrapper } from './styles';

import BadgeBomKm from '../../images/icons/badge_bom_km.svg';
import BadgeCautelar from '../../images/icons/badge_cautelar_aprovada.svg';
import BadgeMargem from '../../images/icons/badge_margem.svg';
import BadgeMecanica from '../../images/icons/badge_mecanica.svg';
import BadgeRevenda from '../../images/icons/badge_revenda.svg';
import PlusTwo from '../../images/icons/plus_two.svg';
import PlusOne from '../../images/icons/plus_one.svg';

const EvaluationBadges = ({ auction }) => {
  const ICONS = {
    BadgeBomKm,
    BadgeCautelar,
    BadgeMargem,
    BadgeMecanica,
    BadgeRevenda,
    PlusTwo,
    PlusOne,
  };

  const ICONS_STATUS = {
    BadgeCautelar: auction?.summary?.precautionaryReport,
    BadgeMecanica: auction?.summary?.allMechanicsCorrect,
    BadgeBomKm: auction?.summary?.kilometrageAcceptable,
    BadgeMargem: auction?.summary?.isBelowThreshold,
    BadgeRevenda: auction?.summary?.fastSpin,
  };

  const getTrueIcons = () => {
    const trueIcons = Object.keys(ICONS_STATUS).filter(key => ICONS_STATUS[key]);

    if (trueIcons.length >= 4) {
      return {
        BadgeCautelar: ICONS.BadgeCautelar,
        BadgeMecanica: ICONS.BadgeMecanica,
        BadgeBomKm: ICONS.BadgeBomKm,
        Plus: trueIcons.length === 4 ? ICONS.PlusOne : ICONS.PlusTwo,
      };
    }

    return trueIcons.reduce((result, key) => {
      result[key] = ICONS[key];
      return result;
    }, {});
  };

  const iconsToDisplay = getTrueIcons();

  return (
    <Container>
      {Object.entries(iconsToDisplay).map(([key, icon], index) => (
        <ImgWrapper key={key}>
          <img src={icon} alt={`Badge ${key}`} />
        </ImgWrapper>
      ))}
    </Container>
  );
};

export default EvaluationBadges;
