import React, { useContext } from 'react';
import { FaCar, FaDollarSign, FaMapMarkerAlt, FaShieldAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useAsync } from 'react-use';
import Box from '@mui/material/Box';

import { getAuctionsLocationStates } from '../../../store/selectors';
import SelectWithSearch from '../components/select-with-search';
import { nextYear } from '../../../constants/appConstants';
import ModalTemplate from '../components/modal-template';
import { fetchModels } from '../../../services/models';
import { transformMarks } from '../../../utils/marks';
import { fetchMarks } from '../../../services/marks';
import LoadingGif from '../../../images/loading.gif';
import Multiselect from '../components/multiselect';
import ICSlider from '../components/ic-slider';
import { Container } from './styles';
import { useConventionalFilter } from '../../../hooks/useConventionalFilter';
import { FiltersContext } from '../../../context/AuctionFilters';

function NewOffersFilters({ states, loading }) {
  const { handleSubmitFilter, handleClearFilter, error, setError } = useConventionalFilter();
  const { cleanSelects } = useContext(FiltersContext);

  const marks = useAsync(async () => {
    const marks = await fetchMarks();
    return transformMarks(marks);
  });

  const models = useAsync(async () => {
    const models = await fetchModels(handleSubmitFilter.values.marks);
    return models.flat();
  }, [handleSubmitFilter.values.marks]);

  function formatStates() {
    return Object.values(states).map(state => {
      return {
        id: state.name,
        label: `${state.name} (${state.count})`,
      };
    });
  }

  return (
    <Container>
      <form>
        <Box sx={{ marginBottom: '30px' }}>
          <ICSlider
            min={0}
            max={900000}
            step={20000}
            previewPrefix="R$"
            label="Preço"
            icon={<FaDollarSign />}
            name="price"
            onChange={handleSubmitFilter.handleChange}
            value={handleSubmitFilter.values.price}
          />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <ICSlider
            min={0}
            max={400000}
            step={25000}
            previewSuffix="Km"
            label="Km"
            icon={<FaCar />}
            name="km"
            onChange={handleSubmitFilter.handleChange}
            value={handleSubmitFilter.values.km}
          />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <ICSlider
            min={0}
            max={100}
            previewSuffix="%"
            label="Score"
            icon={<FaShieldAlt />}
            name="inspectionScore"
            onChange={handleSubmitFilter.handleChange}
            value={handleSubmitFilter.values.inspectionScore}
          />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          {!marks.loading && (
            <SelectWithSearch
              label="Marcas"
              data={marks.value}
              name="marks"
              onChange={handleSubmitFilter.handleChange}
              value={handleSubmitFilter.values.marks}
              reset={cleanSelects}
              type="image"
            />
          )}
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          {!models.loading && (
            <SelectWithSearch
              label="Modelos"
              data={models.value}
              name="models"
              onChange={handleSubmitFilter.handleChange}
              value={handleSubmitFilter.values.models}
              reset={cleanSelects}
            />
          )}
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <ICSlider
            min={2000}
            max={nextYear}
            label="Ano do veículo"
            name="yearInterval"
            onChange={handleSubmitFilter.handleChange}
            value={handleSubmitFilter.values.yearInterval}
            formatValue={false}
          />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <Multiselect
            icon={<FaMapMarkerAlt />}
            label="Selecionar o estado (UF)"
            data={formatStates()}
            name="states"
            onChange={handleSubmitFilter.handleChange}
            value={handleSubmitFilter.values.states}
          />
        </Box>
        <Container.SubmitBtn type="submit" onClick={handleSubmitFilter.handleSubmit}>
          Aplicar filtros
        </Container.SubmitBtn>
      </form>
      <Container.OutlineBtn onClick={handleClearFilter}>Limpar filtros</Container.OutlineBtn>
      {loading && (
        <Container.Loading>
          <img width="40" src={LoadingGif} alt="" />
        </Container.Loading>
      )}
      {error && (
        <ModalTemplate handleCloseModal={() => setError('')}>
          <Container.Message>{error}</Container.Message>
        </ModalTemplate>
      )}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    states: getAuctionsLocationStates(state),
    loading: state.ui.filters.showNewOffersLoading,
  };
}

export default connect(mapStateToProps)(NewOffersFilters);
