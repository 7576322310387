import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 160px;

  max-width: 856px;
  width: 100%;

  padding: 24px 32px;
  margin-bottom: 24px;

  background-color: #f1faff;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    gap: 16px 0;
    padding: 24px 20px;
    margin-bottom: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  .description {
    font: 500 normal 1rem/22px 'Nunito', sans-serif;
    color: #2f3741;
  }
`;

Content.Price = styled(Content)`
  @media screen and (max-width: 1025px) {
    flex-direction: row;
    gap: 0 32px;
  }
`;

Content.Price.Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .price-label .price-number {
    font: 500 normal 1rem/22px 'Nunito', sans-serif;
    color: #2f3741;
  }

  .price-label {
    @media screen and (max-width: 1025px) {
      font-size: 14px;
    }
  }

  .price-number {
    font-weight: 800;
    font-size: 20px;
    @media screen and (max-width: 1025px) {
      font-size: 18px;
    }
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f1faff;
  border-radius: 3px;

  .badge-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 52px;
    padding: 12px 16px;

    background-color: #2274a5;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .badge-title {
    font: 500 normal 1rem/22px 'Nunito', sans-serif;
  }

  .badge-title {
    display: flex;
    align-items: center;

    height: 52px;
    padding: 12px 10px;

    font-weight: 700;
    font-size: 20px;
    color: #fff;

    background-color: #063856;
  }

  .geometric {
    background-color: #063856;
    border-top: 52px solid transparent;
    border-right: 20px solid #f1faff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
