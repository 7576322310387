import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';
import { rem } from 'polished';
import ReactModal from 'react-modal';

import { Box, Text } from '../../abstract';
import { CloseButton } from './CloseButton';
import { CarouselPhoto } from '../../index';

const Tumbnail = styled(Carousel)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  .BrainhubCarouselItem {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .BrainhubCarouselItem--active {
    opacity: 1;
  }
  img {
    height: 5rem;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;

const Centered = styled(Box).attrs({ top: { sm: '8rem', md: '110px' } })`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const HeaderWrapper = styled(Box).attrs({
  color: 'primaries.Taxi',
  left: { sm: '10px', md: '48px' },
  right: { sm: '40px', md: '94px' },
})`
  position: absolute;
  top: 20px;
`;

const SlideFooter = styled(Box)`
  width: 100%;
  color: #ffffff;
  padding-top: 1px;
  margin-top: -1px;
`;
export const PhotoGallery = ({ slideIndex = 0, images, photos, Header, Footer, onDismiss }) => {
  const [slide, setSlide] = useState(slideIndex);
  const total = images ? images.length : photos.length;
  return (
    <ReactModal
      onRequestClose={onDismiss}
      style={{
        content: {
          position: 'absolute',
          background: 'transparent',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          padding: 0,
          zIndex: 99992,
          border: 0,
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.95)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 99991,
        },
      }}
      isOpen
    >
      <HeaderWrapper>{Header}</HeaderWrapper>
      <CloseButton onClick={onDismiss} />
      <Centered width={{ sm: 1, md: '50%' }}>
        <Box position="relative" width={1}>
          {images && images[slide] && (
            <Text
              as="h1"
              fontSize={3}
              color="white"
              p={{
                sm: 1,
                md: 0,
              }}
              mb={{
                sm: 0,
                md: 2,
              }}
            >
              {images[slide].description || ''}
            </Text>
          )}
          <CarouselPhoto
            counter
            active={slide}
            bigArrows
            height={{ sm: '50vh', md: rem(510) }}
            photos={images ? images : photos}
            onChange={setSlide}
          />
        </Box>

        <SlideFooter>{Footer}</SlideFooter>
      </Centered>

      {total > 1 && (
        <Tumbnail
          value={slide}
          slidesPerPage={8}
          centered
          onChange={setSlide}
          breakpoints={{
            780: {
              slidesPerPage: 5,
            },
            500: {
              slidesPerPage: 3,
            },
          }}
        >
          {images
            ? images.map((photo, i) => (
                <img
                  key={photo.img.alt}
                  src={photo.img.src}
                  alt={photo.img.alt}
                  onClick={() => {
                    setSlide(i);
                  }}
                />
              ))
            : photos.map((photo, i) => (
                <img
                  src={photo}
                  alt={photo}
                  key={photo}
                  onClick={() => {
                    setSlide(i);
                  }}
                />
              ))}
        </Tumbnail>
      )}
    </ReactModal>
  );
};
