import React, { useState, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box } from './abstract';
import { CarouselArrow } from './';

import SealImage from '../images/selo-qualidade-maxima.png';

const CarouselWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  min-height: 100%;
  backface-visibility: hidden;
  transform: translateZ(0);
  display: block;
`;

const CarouselTrack = styled(Box)`
  position: relative;
  display: flex;
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  .slide {
    flex-shrink: 0;
    ${({ clickable }) => clickable && 'cursor: pointer;'};
  }
  .lazy img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

const Counter = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #ffffff;
  font-size: 0.5rem;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  ${({ big }) =>
    big &&
    `
  background: rgba(0, 0, 0, 0.57);
  padding: 1em;
  border-radius: 2px;
  
  `};
`;

const Dots = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  z-index: 1;
`;

const Dot = styled(Box)`
  width: 0.5rem;
  height: 0.5rem;
  background: white;
  border-radius: 50%;
  margin: 0.2rem;
  border: 0.063rem solid rgba(255, 255, 255, 0.5);
  background-color: ${({ active }) => (active ? '#fff' : 'transparent')};
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
`;

const Gradient = styled.div`
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #ffffff 75%);
  display: block;
  position: absolute;
  top: 0;
  width: 4rem;
  height: 9rem;
  z-index: 1;
  pointer-events: none;
  @media only screen and (max-width: 770px) {
    display: none;
  }
  ${({ left }) =>
    left
      ? `
    left: 0;
    transform: rotate(180deg);
  `
      : `
    right: 0;
  `};
`;

const Seal = styled.img`
  position: absolute;
  bottom: 0px;
  right: 2px;
  max-width: 63px;
  width: 100%;
  max-height: 63px;
  height: 100%;
`;

export const Carousel = ({
  bigArrows = false,
  height = 'auto',
  onClickSlide,
  onChange,
  arrows = false,
  dots = false,
  children,
  participate,
  counter = false,
  active = 0,
  fadeOut = false,
  sm = 1,
  md = 1,
  lg = 1,
  href,
  showMaximumQualitySeal = false,
  ...etc
}) => {
  const getPerPage = n => (n < 720 ? sm : n > 720 && n < 920 ? md : lg);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(getPerPage(window.innerWidth));
  const pages = React.Children.count(children) / perPage;
  let xDown, yDown;

  React.useEffect(() => {
    const handleResize = e => {
      const newPerPage = getPerPage(e.currentTarget.innerWidth);
      if (newPerPage !== perPage) {
        setPerPage(newPerPage);
        setPage(0);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (active !== page) {
      setPage(active);
    }
  }, [active]);

  const handleSlideClick = n => {
    onClickSlide && onClickSlide(n);
  };
  const handleChange = n => {
    setPage(n);
    onChange && onChange(n);
  };

  const handleTouchStart = e => {
    xDown = e.touches[0].clientX;
    yDown = e.touches[0].clientY;
  };
  const handleTouchMove = e => {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;
    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        nextSlide();
        /* left swipe */
      } else {
        prevSlide();
        /* right swipe */
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };
  const nextSlide = () => {
    if (!!etc.trackCarousel) {
      etc.trackCarousel();
    }

    if (pages % 1 !== 0 && page + 1 >= pages - 1) {
      handleChange(pages - 1);
    } else {
      if (page + 1 < pages) {
        handleChange(page + 1);
      }
    }
  };
  const prevSlide = () => {
    if (!!etc.trackCarousel) {
      etc.trackCarousel();
    }

    if (page % 1 !== 0) {
      handleChange(Math.floor(page));
    } else {
      if (page - 1 >= 0) {
        handleChange(page - 1);
      }
    }
  };
  const dotList = [];
  for (let i = 0; i < pages; i++) {
    dotList.push(i);
  }

  return (
    children && (
      <CarouselWrapper {...etc}>
        {fadeOut && (
          <>
            <Gradient left />
            <Gradient />
          </>
        )}
        {(arrows || bigArrows) && (
          <>
            <CarouselArrow big={bigArrows} onClick={prevSlide} />
            <CarouselArrow big={bigArrows} right onClick={nextSlide} />
          </>
        )}
        <CarouselTrack
          as={href ? Link : 'div'}
          to={href}
          css={{
            transform: `translateX(-${page * 100}%)`,
          }}
          height={participate ? 261 : height}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          clickable={onClickSlide ? true : false}
        >
          {React.Children.map(children, (child, i) =>
            cloneElement(child, {
              width: `${100 / perPage}%`,
              className: 'slide',
              onClick: () => {
                handleSlideClick(i);
              },
              style: { width: `${100 / perPage}%` },
            })
          )}
        </CarouselTrack>

        {counter && (
          <Counter big={bigArrows}>
            {Math.ceil(page + 1)}/{Math.ceil(pages)}
          </Counter>
        )}
        {dots && (
          <Dots>
            {dotList.map(el => (
              <Dot
                key={el}
                active={el === page}
                onClick={() => {
                  handleChange(el);
                }}
              />
            ))}
          </Dots>
        )}
        {showMaximumQualitySeal ? <Seal src={SealImage} /> : null}
      </CarouselWrapper>
    )
  );
};
