import { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nextYear } from '../constants/appConstants';
import { FiltersContext, useFormFilters } from '../context/AuctionFilters';
import { getAuctions } from '../services/auctions';
import { fetchedNewOffers, handleFilters } from '../store/actions/auction.actions';
import { clearCategoryFilter } from '../store/actions/ui.actions';
import { formatAuctionObjectKeys, getNewOffersAuctions } from '../utils/auction';
import { getCurrentElapsed } from '../utils/dateUtils';
import { transformFilters } from '../utils/filter';
import { useFilter } from './useFilter';
import { useSegment } from './useSegment';

const INITIAL_STATE = {
  yearInterval: [2000, nextYear],
  inspectionScore: [0, 100],
  search: '',
  models: [],
  states: [],
  marks: [],
  price: [0, 900000],
  km: [0, 400000],
};

export const useConventionalFilter = () => {
  let state;
  let auctions;
  let requestStartTime;
  let newOffers;

  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const filterValues = useSelector(state => state.ui.filters.newOffers);
  const user = useSelector(state => state.authentication.user);
  const relatedDealers = useSelector(state => state.user.relatedDealers);
  const applyedFilter = useSelector(state => state.ui.filters.newOffersFiltersApplyed);

  const { clearFilter, filterList } = useFilter();
  const { setCleanSelects } = useContext(FiltersContext);
  const { trackFilter } = useSegment();

  const handleSubmitFilter = useFormFilters({
    initialValues: { ...filterValues },
    onSubmit: async values => {
      try {
        state = { newOffers: values, showNewOffersLoading: true };

        if (window.innerWidth < 768) state.showNewOffersFilters = false;
        dispatch(handleFilters(state));

        let parsedFilters = transformFilters(values);
        parsedFilters = new URLSearchParams(parsedFilters).toString();
        auctions = await getAuctions(parsedFilters);

        if (auctions?.length < 1) {
          throw Error('Não foi encontrado leilões para os filtros selecionados');
        }

        requestStartTime = getCurrentElapsed();
        auctions = auctions?.map(auction =>
          formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers)
        );
        newOffers = getNewOffersAuctions(auctions);

        dispatch(fetchedNewOffers(newOffers));
        dispatch(handleFilters({ newOffersFiltersApplyed: true }));
        trackFilter('conventional', values, newOffers.length);
      } catch (e) {
        setError(e.message);
      } finally {
        dispatch(handleFilters({ showNewOffersLoading: false }));
        dispatch(clearCategoryFilter());
        clearFilter();
      }
    },
  });

  const handleClearFilter = async () => {
    try {
      state = { newOffers: INITIAL_STATE, showNewOffersLoading: true };

      if (window.innerWidth < 768) state.showNewOffersFilters = false;
      dispatch(handleFilters(state));

      handleSubmitFilter.setValues({ ...INITIAL_STATE });
      setCleanSelects(true);

      auctions = await getAuctions();
      requestStartTime = getCurrentElapsed();

      auctions = auctions.map(auction =>
        formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers)
      );
      newOffers = getNewOffersAuctions(auctions);

      dispatch(fetchedNewOffers(newOffers));
      dispatch(handleFilters({ newOffersFiltersApplyed: false }));
    } catch (e) {
      setError(e.message);
      setCleanSelects(false);
    } finally {
      dispatch(handleFilters({ showNewOffersLoading: false }));
      setCleanSelects(false);
    }
  };

  useEffect(() => {
    if (applyedFilter && filterList.length > 0) {
      handleClearFilter();
    }
  }, [filterList]);

  return { handleSubmitFilter, handleClearFilter, error, setError };
};
