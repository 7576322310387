import React from 'react';
import { MdClose } from 'react-icons/md';
import { themeGet } from 'styled-system';

import { Button } from '../../abstract';

export const CloseButton = props => (
  <Button
    variant="link.inverse"
    position="absolute"
    right={{ sm: 0, md: 1 }}
    top={{ sm: 0, md: 18 }}
    px={{ sm: 0, md: 3 }}
    {...props}
  >
    <MdClose size={40} color={themeGet('colors.primaries.Taxi')(props)} />
  </Button>
);
