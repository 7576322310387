import React, { useState, useEffect } from 'react';
import {
  Container,
  CarContent,
  CarImg,
  CarContainer,
  UserContainer,
  UserName,
  OtherInformations,
  TextContainer,
  UserContainerMobile,
  StatusContent,
  CardContainer,
  AuctionInfoContainer,
  DropDownContainer,
  GreenArrowContainer,
  MobileSlugContainer,
  IconsDiv,
  MobileSlugContainerArrow,
  StatusIcon,
  TagText,
  UserNameDiv,
  UserContainerTag,
  UserNameCalendarDiv,
  CalendarDiv,
  InfosDiv,
  InfoLinkText,
  AvaliationLinkContainer,
  AuctionInfoContainerId,
  Divider,
  DividerContainer,
  TagDeskDiv,
  SlugImageDiv,
  SlugCalendarContainer,
  ArrowCalendarDiv,
  MultiTagContainer,
  AdditionalServicesContainer,
} from './styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CarIdIcon from '../../../../images/icons/fi-bs-car.svg';
import CalendarIcon from '../../../../images/icons/fi-bs-calendar.svg';
import UserIdIcon from '../../../../images/icons/purchaseAvatar.svg';
import GreenArrowUp from '../../../../images/icons/greenArrowUp.svg';
import GreenArrowDown from '../../../../images/icons/greenArrowDown.svg';
import GreenArrowUpMobile from '../../../../images/icons/mobileGAup.svg';
import GreenArrowDownMobile from '../../../../images/icons/mobileGAdown.svg';
import { CardItem } from '../CardItem';
import { AdditionalServices } from '../AdditionalServices';
import { generateDocExist } from '../../../../services/generate-doc';

export const NewListItem = ({
  slug,
  events,
  step,
  src,
  onClick,
  usedUserTaxId,
  vsrId,
  showFinCredi,
  winningBid,
  year,
  make,
  model,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const [open, setOpen] = useState();
  const [responseDocExist, setResponseDocExist] = useState();

  const {
    active,
    timestamp,
    all,
    lastUpdated,
    offerAccepted,
    releaseDownloadDoc,
    releaseWait,
    pdfLink,
  } = events;

  const data = new Date(timestamp);
  const dia = data.getUTCDate();
  const mes = data.getUTCMonth() + 1;
  const ano = data.getUTCFullYear();

  const dataFormatada = `${dia.toString().padStart(2, '0')}/${mes
    .toString()
    .padStart(2, '0')}/${ano}`;

  const errorIndex = all.findIndex(item => item.state === 'error');
  const itemsToRender = errorIndex !== -1 ? all.slice(0, errorIndex + 1) : all;
  const errorTag = all.filter(item => item.state === 'error');
  const activeTags = all.filter(item => item.state === 'active');

  if (active?.state === 'done') {
    activeTags.push(active);
  }

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth <= 760) {
        return;
      }

      if (!isMobile && window.innerWidth > 760) {
        return;
      }

      if (isMobile && window.innerWidth > 760) {
        setIsMobile(false);
      }

      if (!isMobile && window.innerWidth <= 760) {
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const fetchData = async type => {
    try {
      const response = await generateDocExist(vsrId, type);
      setResponseDocExist(response);
    } catch (error) {
      console.error(error);
    }
  };

  const CnpjInfo = () => {
    return (
      <AuctionInfoContainer>
        <IconsDiv>
          <img className="icon" src={UserIdIcon} alt="ícone" />
        </IconsDiv>
        <OtherInformations>
          {usedUserTaxId.type === 'CPF' ? 'CPF: ' : 'CNPJ: '}
          {usedUserTaxId.taxIdentificationNumber}
        </OtherInformations>
      </AuctionInfoContainer>
    );
  };

  const CarId = () => {
    return (
      <AuctionInfoContainerId>
        <IconsDiv>
          <img className="icon" src={CarIdIcon} alt="ícone" />
        </IconsDiv>
        <a target="_blank" href={`/detalhes/${vsrId}`}>
          <OtherInformations style={{ textDecoration: 'underline' }}>
            {'ID: ' + vsrId}
          </OtherInformations>
        </a>
      </AuctionInfoContainerId>
    );
  };

  const CalendarInfo = () => {
    return (
      <CalendarDiv>
        <IconsDiv>
          <img className="icon" src={CalendarIcon} alt="ícone" />
        </IconsDiv>
        <OtherInformations>{dataFormatada}</OtherInformations>
      </CalendarDiv>
    );
  };

  return (
    <>
      {isMobile ? (
        <CardContainer onClick={onClick}>
          <Container>
            <TextContainer>
              <MobileSlugContainer
                onClick={() => {
                  setOpen(!open);
                  fetchData('cautelar');
                }}
              >
                <CarImg>
                  <LazyLoadImage as="img" src={src} alt={slug} effect="opacity" width="100%" />
                </CarImg>
                <InfosDiv>
                  <MobileSlugContainerArrow>
                    <CarContent>{slug}</CarContent>
                    <GreenArrowContainer>
                      {open ? (
                        <img className="icon" src={GreenArrowUpMobile} alt="ícone" />
                      ) : (
                        <img className="icon" src={GreenArrowDownMobile} alt="ícone" />
                      )}
                    </GreenArrowContainer>
                  </MobileSlugContainerArrow>
                  <UserContainerMobile>
                    {usedUserTaxId && <UserName>{usedUserTaxId.nickName}</UserName>}
                    {timestamp && <CalendarInfo />}
                  </UserContainerMobile>
                </InfosDiv>
              </MobileSlugContainer>

              <MultiTagContainer
                onClick={() => {
                  setOpen(!open);
                  fetchData('cautelar');
                }}
              >
                {(errorTag.length > 0 ? errorTag : activeTags).map((item, index) => (
                  <TagDeskDiv>
                    <StatusContent key={index} backgroundColor={item?.color?.light}>
                      <StatusIcon>
                        <img className="icon" src={item?.icon.icon} alt="ícone" />
                      </StatusIcon>
                      <TagText>{item?.msg?.short ?? item?.msg?.long}</TagText>
                    </StatusContent>
                  </TagDeskDiv>
                ))}
              </MultiTagContainer>

              {open && (
                <>
                  <UserContainer>
                    <AvaliationLinkContainer>
                      {usedUserTaxId && <CnpjInfo />}
                      <InfoLinkText target="_blank" href={`/detalhes/${vsrId}`}>
                        Ver avaliação
                      </InfoLinkText>
                    </AvaliationLinkContainer>
                    <CarId />
                  </UserContainer>
                  {offerAccepted && (
                    <AdditionalServicesContainer>
                      <AdditionalServices
                        slug={slug}
                        inspectionId={vsrId}
                        winningBid={winningBid}
                        year={year}
                        make={make}
                        model={model}
                        pdfLink={pdfLink}
                        releaseDownloadDoc={releaseDownloadDoc}
                        alreadyExist={responseDocExist}
                        releaseWait={releaseWait}
                      />
                    </AdditionalServicesContainer>
                  )}
                </>
              )}
            </TextContainer>
          </Container>
          {!open && <Divider />}
        </CardContainer>
      ) : (
        <CardContainer onClick={onClick}>
          <Container>
            <TextContainer>
              <SlugImageDiv
                onClick={() => {
                  setOpen(!open);
                  fetchData('cautelar');
                }}
              >
                <CarImg>
                  <LazyLoadImage as="img" src={src} alt={slug} effect="opacity" width="100%" />
                </CarImg>
                <SlugCalendarContainer>
                  <CarContainer>
                    <CarContent>{slug}</CarContent>
                  </CarContainer>
                  <UserNameCalendarDiv>
                    <UserName>{usedUserTaxId?.nickName}</UserName>
                    <ArrowCalendarDiv>
                      {timestamp && <CalendarInfo />}
                      <GreenArrowContainer>
                        {open ? (
                          <img className="icon" src={GreenArrowUp} alt="ícone" />
                        ) : (
                          <img className="icon" src={GreenArrowDown} alt="ícone" />
                        )}
                      </GreenArrowContainer>
                    </ArrowCalendarDiv>
                  </UserNameCalendarDiv>
                </SlugCalendarContainer>
              </SlugImageDiv>
              <UserContainerTag>
                {usedUserTaxId && (
                  <MultiTagContainer
                    onClick={() => {
                      setOpen(!open);
                      fetchData('cautelar');
                    }}
                  >
                    {(errorTag.length > 0 ? errorTag : activeTags).map((item, index) => (
                      <TagDeskDiv>
                        <StatusContent key={index} backgroundColor={item?.color?.light}>
                          <StatusIcon>
                            <img className="icon" src={item?.icon.icon} alt="ícone" />
                          </StatusIcon>
                          <TagText>{item?.msg?.short ?? item?.msg?.long}</TagText>
                        </StatusContent>
                      </TagDeskDiv>
                    ))}
                  </MultiTagContainer>
                )}
              </UserContainerTag>
              {open ? (
                <>
                  <UserContainer>
                    {usedUserTaxId && (
                      <>
                        <CnpjInfo />
                      </>
                    )}
                    <CarId />
                    <InfoLinkText target="_blank" href={`/detalhes/${vsrId}`}>
                      Ver avaliação
                    </InfoLinkText>
                  </UserContainer>
                  {offerAccepted && (
                    <AdditionalServicesContainer>
                      <AdditionalServices
                        slug={slug}
                        inspectionId={vsrId}
                        winningBid={winningBid}
                        year={year}
                        make={make}
                        model={model}
                        pdfLink={pdfLink}
                        releaseDownloadDoc={releaseDownloadDoc}
                        alreadyExist={responseDocExist}
                        releaseWait={releaseWait}
                      />
                    </AdditionalServicesContainer>
                  )}
                </>
              ) : (
                <Divider />
              )}
            </TextContainer>
          </Container>
        </CardContainer>
      )}
      {open && (
        <>
          <DropDownContainer>
            {itemsToRender.map((itemsToRender, index) => (
              <CardItem
                key={index}
                all={itemsToRender}
                lastUpdated={lastUpdated}
                showFinCredi={showFinCredi}
              />
            ))}
          </DropDownContainer>
          {open && (
            <DividerContainer>
              <Divider />
            </DividerContainer>
          )}
        </>
      )}
    </>
  );
};
