import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Text,
  NormalText,
  BoldText,
  ValueContainer,
  TextInfo,
  DownloadButton,
  ModalTitle,
  SubTitleModal,
  ModalTitleContainer,
  SubTitleModalContainer,
  ConfirmButton,
  ConfirmButtonText,
  ConfirmButtonContainer,
  CancelText,
  CancelTextContainer,
  CloseIconContainer,
  ModalContent,
  CloseIconDiv,
  WaitContainer,
  WaitText,
  GenerateContainer,
  DownloadContainer,
  DownloadText,
} from './styles';
import Modal from 'react-modal';
import { enableTooltip } from '../../../../store/tooltip/actions';

import CautelarIcon from '../../../../images/icons/generateCautelarIcon.svg';
import CloseIcon from '../../../../images/icons/closeIconModal.svg';
import WaitIcon from '../../../../images/icons/waitIconAdd.svg';
import DownloadIcon from '../../../../images/icons/downloadIcon.svg';
import { useSegment } from '../../../../hooks/useSegment';
import { generateDoc } from '../../../../services/generate-doc';

export const AdditionalServices = ({
  inspectionId,
  winningBid,
  year,
  make,
  model,
  pdfLink,
  releaseDownloadDoc,
  alreadyExist,
  releaseWait,
}) => {
  const { trackAdditionalService } = useSegment();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const [respondeCreatDoc, setRespondeCreatedDoc] = useState();

  const fetchData = async type => {
    try {
      const response = await generateDoc(inspectionId, type);
      setRespondeCreatedDoc(response);
      dispatch(enableTooltip({ messageId: 13 }));
    } catch (error) {
      console.error(error);
      dispatch(enableTooltip({ messageId: 14 }));
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo escurecida
    },
    content: {
      width: isMobile ? 'auto' : '500px',
      height: 'fit-content',
      margin: 'auto',
      border: 'none',
      padding: '10px',
    },
  };

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth <= 760) {
        return;
      }

      if (!isMobile && window.innerWidth > 760) {
        return;
      }

      if (isMobile && window.innerWidth > 760) {
        setIsMobile(false);
      }

      if (!isMobile && window.innerWidth <= 760) {
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return alreadyExist !== undefined ? (
    <>
      {respondeCreatDoc === undefined && !alreadyExist.exists ? (
        <Container>
          <ValueContainer>
            <img className="icon" src={CautelarIcon} alt="ícone" />
            <Text>
              Perícia Cautelar <NormalText>por R$ 90,00</NormalText>
            </Text>
          </ValueContainer>
          <>
            <TextInfo>Você já pode adquirir a perícia cautelar para esse carro!</TextInfo>
            <DownloadButton
              onClick={() => {
                setOpen(!open);
              }}
            >
              Baixar agora
            </DownloadButton>
          </>
        </Container>
      ) : (
        <GenerateContainer>
          <ValueContainer>
            <img className="icon" src={CautelarIcon} alt="ícone" />
            <Text>Perícia Cautelar</Text>
          </ValueContainer>

          {pdfLink !== null && releaseDownloadDoc ? (
            <DownloadContainer href={pdfLink} target="_blank">
              <img className="icon" src={DownloadIcon} alt="ícone" />
              <DownloadText>Baixar</DownloadText>
            </DownloadContainer>
          ) : (
            <WaitContainer>
              {/*      {releaseWait ? (
                <>
                  <img className="icon" src={WaitIcon} alt="ícone" style={{ paddingRight: 10 }} />
                  <WaitText>Aguardando</WaitText>
                </>
              ) : (
                <WaitText>Adquirido</WaitText>
              )} */}

              <img className="icon" src={WaitIcon} alt="ícone" style={{ paddingRight: 10 }} />
              <WaitText>Aguardando</WaitText>
            </WaitContainer>
          )}
        </GenerateContainer>
      )}
      <Modal
        isOpen={open}
        style={customStyles}
        shouldFocusAfterRender
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        shouldReturnFocusAfterClose
      >
        <CloseIconContainer>
          <CloseIconDiv
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img className="icon" src={CloseIcon} alt="ícone" />
          </CloseIconDiv>
        </CloseIconContainer>

        <ModalContent>
          <ModalTitleContainer>
            <ModalTitle>Confirmar solicitação de serviços adicionais </ModalTitle>
          </ModalTitleContainer>

          <SubTitleModalContainer>
            <SubTitleModal>
              Deseja confirmar a solicitação de Perícia Cautelar{' '}
              <BoldText>no valor de R$ 90,00?</BoldText>
            </SubTitleModal>
          </SubTitleModalContainer>

          <ConfirmButtonContainer>
            <ConfirmButton
              onClick={() => {
                setOpen(!open);
                trackAdditionalService(
                  new Date().getTime(),
                  inspectionId,
                  'Cautelar',
                  year,
                  make,
                  model,
                  winningBid
                );
                fetchData('cautelar');
              }}
            >
              <ConfirmButtonText>Confirmar solicitação</ConfirmButtonText>
            </ConfirmButton>
          </ConfirmButtonContainer>

          <CancelTextContainer
            onClick={() => {
              setOpen(!open);
            }}
          >
            <CancelText>Cancelar</CancelText>
          </CancelTextContainer>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};
