import React from 'react';
import {
  Button,
  FincreditContainer,
  CardTitle,
  CardSubTitle,
  Text,
  TextDiv,
  TopDiv,
} from './styles';

import checkIcon from '../../../../images/icons/check-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { sendEvent } from '../../../../helpers/googleAnalytics';
import { useUserProfileContext } from '../../../../context/UserProfileContext';
import { useSegment } from '../../../../hooks/useSegment';

export function FincredCard() {
  const { id, email, dealershipName } = useSelector(state => state.authentication.user);
  const { currentUserProfile } = useUserProfileContext();
  const { trackFinCredit } = useSegment();

  return (
    <FincreditContainer>
      <TopDiv>
        <CardTitle>ACELERE SUAS VENDAS COM O PAGAMENTO FACILITADO</CardTitle>
        <CardSubTitle>Parcele as suas compras em até 6x!</CardSubTitle>
      </TopDiv>

      <TextDiv>
        <img src={checkIcon} />
        <Text>Atraia mais clientes oferecendo mais opções de veículo em estoque e venda mais!</Text>
      </TextDiv>
      <Button
        onClick={() => {
          sendEvent('financingTracker', {
            dealershipId: id,
            dealershipEmail: email,
            dealershipName: dealershipName,
            phoneNumber: currentUserProfile?.phoneNumber,
            eventCategory: 'Auctions',
            eventAction: 'ClickCard',
            eventLabel: 'Financiamento',
          });
          trackFinCredit(new Date().getTime(), 'Novas Ofertas');
          window.open('/pagamento-facilitado', '_blank');
        }}
      >
        Simular Agora
      </Button>
    </FincreditContainer>
  );
}
