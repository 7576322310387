import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../enhanced/Modal';

import withMakeOffer from '../../hoc/WithMakeOffer';

import { AuctionItemTimer } from '../../enhanced/AuctionItemTimer';

import { toggleModal } from '../../../store/actions/ui.actions';
import {
  AUCTION_OFFER_KEY,
  AUCTION_BUY_KEY,
  AUCTION_CONFIRM_MAKE_OFFER_KEY,
  AUCTION_BUY_FOR,
  CONFIRM_BUY_FOR,
} from '..';
import { getSelectedAuctionRemainingTime } from '../../../store/selectors/auctionSelectors';
import { TimingAuctionContextProvider } from '../../../context/TimingAuctionContext';
import get from 'lodash/get';
import { toAmount } from '../../../utils/number';
import SingleOfferAction from '../../BidActions/SingleOfferAction';
import {
  Content,
  VehicleInformationsContainer,
  VehicleNameAndInfos,
  DealExclusivelyContainer,
  DealExclusivelyLabel,
  Divider,
  LastOfferContainer,
  LastOfferLabel,
  LastOfferValue,
  SingleOfferContainer,
  SingleOfferLabel,
  ReturnNewOffers,
  ColorBox,
  ColorBoxTitle,
  BuyForContent,
  BuyForText,
} from './styles';

import { useBid } from '../../../hooks/useBid';
import { useFilter } from '../../../hooks/useFilter';
import { onBidError } from '../../../store/actions/auction.actions';
import { sendEvent } from '../../../helpers/googleAnalytics';
import { useSegment } from '../../../hooks/useSegment';
import { Text } from '../../abstract';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { formatPrice } from '../../../utils/formatPrice';
import { Button } from '../../Buttons';

const _SingleOfferModal = ({
  handleAmountIncrement,
  handleAmountChange,
  handleQuickAmount,
  handleBlur,
  amountValue,
  submitOffer,
  minimumBid,
  multipliers,
  selectedAuction,
  bidErrorMessage,
  bidSuccess,
  makeOfferLoading,
  showDealersDropdown,
  relatedDealers,
  onDealerChange,
  onTaxProfileChange,
  selectedDealer,
  selectedTaxProfile,
  taxProfiles,
  show,
  selectedAuctionRemainingTime,
  dealerId,
  updateAmount,
  ...props
}) => {
  const dispatch = useDispatch();

  const [size, setSize] = useState('makeOfferLg');
  const [showSlider, setShowSlider] = useState(false);
  const [quickButtons, setQuickButtons] = useState([]);

  const extras = useSelector(state => state.ui.modals[AUCTION_OFFER_KEY].extras);
  const { user } = useSelector(state => state.authentication);

  const { currentOfferLabel, negotiationExclusivityLabel } = useBid(selectedAuction);
  const { filterList } = useFilter();
  const { trackConfirmBid } = useSegment(extras?.isRecommended);

  const setSection = typeof extras !== null ? extras?.section : undefined;

  const _auction = useMemo(() => {
    return {
      ...selectedAuction.auction,
      remainingTime: selectedAuctionRemainingTime,
    };
  }, [selectedAuction, selectedAuctionRemainingTime]);

  const handleNegotiateExclusivityModal = useCallback(() => {
    props.toggleModal({ key: AUCTION_BUY_KEY, show: true });
    props.toggleModal({ key: AUCTION_OFFER_KEY, show: false });
  }, [props.toggleModal]);

  const handleBuyForModal = useCallback(() => {
    props.toggleModal({ key: AUCTION_BUY_FOR, show: true });
    props.toggleModal({ key: AUCTION_OFFER_KEY, show: false });
  }, [props.toggleModal]);

  const handleSubmitOffer = useCallback(() => {
    const bn = selectedAuction?.summary?.buynowPrice;
    const bf = selectedAuction?.summary?.buyForPrice;

    if (!bf?.enabled && bn?.enabled && toAmount(bn?.amount) <= toAmount(amountValue)) {
      props.toggleModal({ key: AUCTION_OFFER_KEY, show: false });
      props.toggleModal({
        key: AUCTION_CONFIRM_MAKE_OFFER_KEY,
        show: true,
        extras: {
          auction: selectedAuction,
          currentOffer: amountValue,
          section: setSection,
          submitOffer,
          quickButtons,
          setQuickButtons,
        },
      });

      return;
    }

    if (bf?.enabled && toAmount(bf?.amount) <= toAmount(amountValue)) {
      props.toggleModal({ key: AUCTION_OFFER_KEY, show: false });
      props.toggleModal({
        key: CONFIRM_BUY_FOR,
        show: true,
        extras: { bid: toAmount(amountValue), onSubmit: submitOffer },
      });

      return;
    }

    if (!selectedAuction.auction.participating) {
      sendEvent('auctionTracker', {
        dealershipId: user.id,
        dealershipEmail: user.email,
        dealershipName: user.dealershipName,
        inspectionId: selectedAuction._id,
        bidAmount: amountValue,
        bidType: filterList.length > 0 ? 'categorySelected' : 'simples',
        eventCategory: 'Auctions',
        eventAction: 'Participar do leilão',
        eventLabel: 'Lance realizado',
      });
    }
    submitOffer();
    trackConfirmBid({
      inspection_id: selectedAuction._id,
      auction_id: selectedAuction.auction.idAuction,
      bidAmount: toAmount(amountValue),
      type: 'singleBid',
      section: setSection,
      quickButtons,
    });
    setQuickButtons([]);
  }, [
    amountValue,
    selectedAuction,
    props.toggle,
    submitOffer,
    trackConfirmBid,
    setSection,
    quickButtons,
  ]);

  const useReturnNewOffers = useCallback(() => {
    if (!selectedAuction?.auction?.participating || user.isNinja()) return null;

    return (
      <ReturnNewOffers onClick={() => props.toggleModal({ key: AUCTION_OFFER_KEY, show: false })}>
        Voltar para Novas ofertas
      </ReturnNewOffers>
    );
  }, [selectedAuction?.auction?.participating, user]);

  useEffect(() => {
    const currentWinner = selectedAuction?.auction?.winningBid?.amount < toAmount(amountValue);

    if (selectedAuction?.auction?.participating && currentWinner) setShowSlider(true);
  }, [selectedAuction, amountValue]);

  useEffect(() => {
    if (!show) {
      dispatch(onBidError(''));
      setShowSlider(false);
    }
  }, [show]);

  window.addEventListener('resize', () => {
    if (window.screen.width >= 768) setSize('makeOfferLg');
  });

  return (
    <TimingAuctionContextProvider auction={_auction}>
      <Modal
        toggle={() => props.toggleModal({ key: AUCTION_OFFER_KEY, show: false })}
        show={show}
        makeOfferSpecificStyle={show ? size : undefined}
      >
        <Content isNinja={user.isNinja()}>
          <VehicleInformationsContainer>
            <VehicleNameAndInfos>
              {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}{' '}
              {selectedAuction?.summary?.yearLabel} - {selectedAuction?.summary?.version}{' '}
              {selectedAuction?.summary?.kmLabel}{' '}
            </VehicleNameAndInfos>

            <AuctionItemTimer showBuyNowButton={false} auction={selectedAuction} />
          </VehicleInformationsContainer>

          {!selectedAuction?.summary?.buyForPrice?.enabled &&
            selectedAuction?.summary?.buynowPrice?.enabled &&
            !showDealersDropdown && (
              <>
                <BuyForContent>
                  <BuyForText>
                    Encerre o leilão com alta chance de compra e negocie com exclusividade.
                  </BuyForText>
                  <Button
                    fontWeight={400}
                    bg="#ff4c00"
                    hover="#ff7941"
                    onClick={handleNegotiateExclusivityModal}
                  >
                    Negocie a partir de{' '}
                    <strong>R$ {formatPrice(selectedAuction?.summary?.buynowPrice?.amount)}</strong>
                  </Button>
                </BuyForContent>
                <Divider>ou</Divider>
              </>
            )}

          {selectedAuction?.summary?.buyForPrice?.enabled && !showDealersDropdown && (
            <>
              <BuyForContent>
                <BuyForText>
                  Preço de venda já negociado com atual proprietário. Compra imediata e sem
                  negociação de valor.
                </BuyForText>
                <Button fontWeight={400} color="#000" bg="#f2c94c" onClick={handleBuyForModal}>
                  Compra garantida por{' '}
                  <strong>R$ {formatPrice(selectedAuction?.summary?.buyForPrice?.amount)}</strong>
                </Button>
              </BuyForContent>
              <Divider>ou</Divider>
            </>
          )}

          <LastOfferContainer showDealersDropdown={showDealersDropdown}>
            <LastOfferLabel>Lance atual</LastOfferLabel>
            <LastOfferValue>R$ {currentOfferLabel}</LastOfferValue>
          </LastOfferContainer>

          <SingleOfferContainer>
            <SingleOfferLabel showDealersDropdown={showDealersDropdown}>
              Faça uma oferta avulsa:
            </SingleOfferLabel>
            <SingleOfferAction
              inputValue={amountValue}
              handleAmountIncrementOrDecrement={handleAmountIncrement}
              onChange={handleAmountChange}
              onBlur={handleBlur}
              onSubmit={handleSubmitOffer}
              showExclusivityButton={false}
              auction={selectedAuction}
              handleQuickKeyboard={handleQuickAmount}
              selectedDealer={dealerId}
              onDealerChange={onDealerChange}
              relatedDealers={relatedDealers}
              showDealersDropdown={showDealersDropdown}
              selectedTaxProfile={selectedTaxProfile}
              onTaxProfileChange={onTaxProfileChange}
              taxProfiles={taxProfiles}
              isLoading={makeOfferLoading && !bidSuccess}
              error={bidErrorMessage}
              showSlider={showSlider}
              isNinja={user.isNinja()}
              updateAmount={updateAmount}
              setQuickButtons={setQuickButtons}
              isRecommended={extras?.isRecommended}
              setSection={setSection}
            />
          </SingleOfferContainer>

          {user.isNinja() ? (
            <ColorBox
              winning={selectedAuction.auction.winning}
              participating={selectedAuction.auction.participating}
            >
              <ColorBoxTitle alignItems="center">
                {selectedAuction.auction.winning ? <MdAttachMoney /> : <MdMoneyOff />}
                <Text
                  style={{
                    font: '700 normal 0.875rem/1em Roboto, sans-serif',
                    color: '#fff',
                  }}
                >
                  {selectedAuction.auction.winning ? ' Ganhando' : ' Perdendo'}
                </Text>
              </ColorBoxTitle>
            </ColorBox>
          ) : null}

          {useReturnNewOffers()}
        </Content>
      </Modal>
    </TimingAuctionContextProvider>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

const mapStateToProps = state => ({
  show: state.ui.modals.auctionOffer.show,
  selectedAuctionRemainingTime: getSelectedAuctionRemainingTime(state),
});

const SingleOfferModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withMakeOffer(_SingleOfferModal));
export { SingleOfferModal, SingleOfferModal as default };
